import React, {useMemo, useRef, useState} from "react";
import Input from "./Input";
import './ContactForm.scss';

// const API_URL = 'http://localhost:3000';
const API_URL = '';

const ContactForm = ({calculatorResult, hideModal}) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [company, setCompany] = useState("");
  const [agree, setAgree] = useState(false);

  const formAlert = useRef(null);

  const inputConfig = useMemo(() => [
    {
      type: "text",
      name: "name",
      text: "Imię",
      value: name,
      onChange: setName,
    },
    {
      type: "text",
      name: "surname",
      text: "Nazwisko",
      value: surname,
      onChange: setSurname,
    },
    {
      type: "text",
      name: "email",
      text: "Email",
      value: email,
      onChange: setEmail,
    },
    {
      type: "text",
      name: "tel",
      text: "Telefon",
      value: tel,
      onChange: setTel,
    },
    {
      type: "text",
      name: "company",
      text: "Firma",
      value: company,
      onChange: setCompany,
    },
    {
      type: "checkbox",
      name: "agree",
      text: "Wyrażam zgodę na przetwarzanie moich danych osobowych w celach związanych przygotowaniem oferty przez Ostendi Global Sp. z o.o. z siedzibą w Warszawie przy ul. Filtrowej 65/50  zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych oraz otrzymywaniem od Ostendi Global Sp. z o.o. informacji  zgodnie z ustawą z dnia 28.07.2002 r. o świadczeniu usług drogą elektroniczną.",
      value: agree,
      onChange: () => setAgree((prevState) => !prevState),
    }
  ], [name, surname, email, tel, company, agree]);

  const submitForm = (e) => {
    e.preventDefault();
    if (!name || !surname || !email || !tel) {
      formAlert.current.innerText = 'Wszystkie pola muszą zostać wypełnione';
    } else if (!agree) {
      formAlert.current.innerText = 'Zgoda jest wymagana';
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      formAlert.current.innerText = 'Adres email jest niepoprawny';
    } else if (!(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3,4})/.test(tel))) {
      formAlert.current.innerText = 'Numer telefonu jest niepoprawny';
    } else {
      formAlert.current.innerText = '';
      fetch(API_URL + '/contact', {
        method: "POST",
        body: JSON.stringify({
          name, surname, email, agree, tel,
          subject: "Wiadomość z kalkulatora ostendi360.pl",
          result: calculatorResult
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if (response.status === 200) {
            alert("Dziękujemy. Wiadomość została wysłana.");
            hideModal();
            resetForm();
          } else {
            alert("Uzupełnij poprawnie wszystkie pola lub spróbuj później.")
          }
        })
        .catch((error) => {
          alert("Wiadomość nie została wysłana, proszę spróbować później.")
        });
    }
  }

  const resetForm = () => {
    setName("");
    setSurname("");
    setEmail("");
    setTel("");
    setCompany("");
    setAgree(false);
  }

  return (
    <>
      <div className="form__error" ref={formAlert}></div>
      <form className="form" method="post" onSubmit={submitForm}>
        {inputConfig.map((input) => {
          return <Input key={input.name} type={input.type} name={input.name}
            text={input.text} value={input.value}
            onChange={(e) => input.onChange(e.target.value)}/>
        })}
        <div className="form__footer">
          <button className="calculator__btn" type="submit">Wyślij</button>
        </div>
      </form>
    </>
  )
}

export default ContactForm;
