import React, {useRef, useMemo, useState, useCallback} from "react"
import ReactDOM from 'react-dom';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reports from "../images/reports.png";
import Wlosinski from "../images/wlosinski.png";
import Eobuwie from "../images/logo/eobuwie.png";
import Steier from "../images/steier.jpg";
import Velux from "../images/logo/velux.png"
import Icon0 from "../images/icons/oszczednosc.svg";
import Icon1 from "../images/icons/ankieta.svg";
import Icon2 from "../images/icons/kompetencje.svg";
import Icon3 from "../images/icons/elastycznosc.svg";
import Icon4 from "../images/icons/czytelne.svg";
import Icon5 from "../images/icons/wielojezycznosc.svg";
import Icon6 from "../images/icons/raporty.svg";
import Icon7 from "../images/icons/bezpieczenstwo.svg";
import Picture from "../components/picture";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import InformationCircleOutlineIcon
  from "mdi-react/InformationCircleOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";
import ContactForm from "../components/ContactForm";
import Input from "../components/Input";
import OgImage from "../images/og-image-cal.jpg";

//const API_URL = 'http://localhost:3000';
const API_URL = '';

const IndexPage = () => (
  <Layout>
    <SEO title="Ostendi Global" image={OgImage}/>
    <section className="calculator section" id="calculator">
      <div className="calculator__lead" data-aos="fade-up">
        Sprawdź, ile będzie Cię kosztować i jak możesz zrealizować badanie kompetencji w oparciu o metodę 360 / 270 / 180 stopni. Dopasuj zakres wsparcia i cenę do Twoich możliwości organizacyjnych. Przeprowadzenie badania kompetencji nigdy nie było tak proste!
      </div>
      <div className="calculator__body">
        <p>Powiedz nam o swoich planach <small>(Uzupełnij niebieskie elementy)</small></p>
        <Calculator/>
      </div>
    </section>
    <section className="ostendi section" id="ostendi">
      <div className="section__title" data-aos="fade-up">
        Dlaczego Ostendi
      </div>
      <div className="ostendi__body">
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon0} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Oszczędność czasu</h3>
            <p>Projekt oceny 360 stopni może być uruchomiony nawet w ciągu jednej godziny. Dane wszystkich osób biorących udział w ocenie mogą być zaimportowane w postaci pliku xls.  Jednorazowo procesem oceny może być objęta dowolna ilość osób, zarówno ocenianych i oceniających.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon1} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Wypróbowane ankiety</h3>
            <p>Nie posiadasz własnego kwestionariusza do oceny? To żaden problem. Posiadamy gotowe, wypróbowane ankiety dla różnych poziomów przywództwa w organizacji.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon2} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Bogactwo kompetencji</h3>
            <p>Na podstawie naszego bogatego słownika kompetencji oraz wskaźników zachowań (pytań) masz możliwość stworzenia szybko i w niezwykle prosty sposób ankiety na potrzeby oceny 360 stopni w Twojej organizacji.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon3} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Elastyczność</h3>
            <p>Menadżerowie mogą̨ podlegać́ ocenie dowolnej ilości osób. Lista oceniających może być́ zmieniana w trakcie badania. Ocena może być dokonywana z maksymalnie sześciu perspektyw, np. przełożony, współpracownik, podwładny, klient itp.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon4} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Czytelne raporty</h3>
            <p>Wyniki każdego badania prezentowane są̨ w niezwykle czytelnym i wyczerpującym raporcie zawierającym ogólne podsumowanie uzyskanych ocen oraz ich szczegółowy obraz na poziomie każdego wymiaru oraz grupy osób oceniających danego menedżera.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon5} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Wielojęzyczność</h3>
            <p>Chcesz przeprowadzić ocenę w wielu językach jednocześnie? To nie problem. Nasz system pozwoli Ci przeprowadzić badanie i wygenerować raporty w różnych językach. </p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon6} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Pełna swoboda</h3>
            <p>Badanie może zawierać dowolną ilość ocenianych kompetencji, przyporządkowanych im pytań oraz rodzaj skali oceny. Jedynymi ograniczeniami są: poprawność metodologiczna, zasadność oceny określonych kompetencji oraz wytrzymałość respondentów.</p>
          </div>
        </div>
        <div className="ostendi__item" data-aos="fade-up">
          <div className="ostendi__item-icon">
            <Picture title="Oszczędność czasu" path={Icon7} />
          </div>
          <div className="ostendi__item-desc">
            <h3>Bezpieczeństwo</h3>
            <p>Korzystamy z nowoczesnych technologii zapewniających poziom bezpieczeństwa zgodny ze specyfikacją OWASP top ten. Stale dokonujemy kopii baz danych i archiwizujemy je na niezależnych serwerach.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="reports section" id="reports">
      <div className="section__title" data-aos="fade-up">
        Przykładowe raporty
      </div>
      <div className="reports__body" data-aos="fade-up">
        <div className="reports__image">
          <Picture title="Raporty" path={Reports} />
        </div>
        <div className="reports__desc">
          <h3>Co wyróżnia nasze raporty</h3>
          <ul>
            <li>Możliwość skorzystania z bogatego słownika kompetencji i twierdzeń podczas badania kompetencji opartej na metodologii 360 stopni.</li>
            <li>Możliwość dodania własnego modelu kompetencyjnego przy jednoczesnym zachowaniu atrakcyjności prezentowanych danych.</li>
            <li>Możliwość zbadania opinii 6 różnych grup oceniających bez konieczności wykorzystania powszechnie znanego podziału na: przełożonego, współpracowników, podwładnych.</li>
            <li>Dowolny rodzaj skali odpowiedzi tak, aby sposób odpowiedzi dopasować do znanej w organizacji formy.</li>
            <li>Raport charakteryzuje się zarówno syntetycznością jak i szczegółowością prezentowanych danych.</li>
          </ul>
        </div>
      </div>
    </section>
    <SliderSection/>
  </Layout>
)

const SliderSection = () => {
  const [active, setActive] = useState(0);
  const slides = useMemo(() => ([
    {
      title: 'Rafał Włosiński',
      logo: Eobuwie,
      alt: 'eobuwie.pl',
      image: Wlosinski,
      desc: 'Dzięki niezwykle elastycznej funkcjonalności platformy Ostendi, dopasowaliśmy badanie do specyfiki naszych potrzeb i mogliśmy zmierzyć dokładnie to, na czym nam zależało na dużej grupie kadry kierowniczej. Ogromnym atutem uzyskanych raportów jest ich przejrzystość, czytelności przy jednoczesnym zachowaniu szczegółowych informacji.',
      position: 'HR Project Manager'
    },
    {
      title: 'Magdalena Steier',
      logo: Velux,
      alt: 'velux',
      image: Steier,
      desc: 'Do badania poziomu kompetencji kadry zarządzającej niższego szczebla wybraliśmy narzędzie 360 degree feedback z platformy Ostendi Management System. Jest to intuicyjnie i przyjazne użytkownikowi narzędzie, które pozwala samodzielnie zarządzać całym procesem od początku do końca. Raporty po badaniu są kompleksowo opracowane i dostępne od razu na platformie.',
      position: 'HR Business Partner'
    }
  ]));
  const onClickLeft = () => {
    const act = active - 1;
    if (act < 0) {
      setActive(slides.length - 1);
    } else {
      setActive(act);
    }
  };
  const onClickRight = () => {
    const act = active + 1;
    if (act > slides.length - 1) {
      setActive(0);
    } else {
      setActive(act);
    }
  }
  return (
    <section className="clients section" id="clients">
      <div className="section__title" data-aos="fade-up">
        Opinie naszych klientów
      </div>
      <div className="clients__body" data-aos="fade-up">
        <div className="clients__arrow --left" onClick={onClickLeft}>
          <ChevronLeftIcon size={30}/>
        </div>
        <div className="clients__arrow --right" onClick={onClickRight}>
          <ChevronRightIcon size={30}/>
        </div>
        <div className="clients__item">
          <div className="clients__image">
            <Picture title={slides[active].title} path={slides[active].image} />
          </div>
          <div className="clients__desc">
            <Picture title={slides[active].alt} path={slides[active].logo} />
            <p>{slides[active].desc}</p>
            <div className="break-line"/>
            <p>
              <strong>{slides[active].title}</strong><br/>
              {slides[active].position}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const Calculator = () => {
  const [range, setRange] = useState(9);
  const [creator, setCreator] = useState(0);
  const [questionnaire, setQuestionnaire] = useState(0);
  const [feedback, setFeedback] = useState(0);
  const [summaryReport, setSummaryReport] = useState(0);
  const [result, setResult] = useState(null);
  const [showRange, setShowRange] = useState(false);
  const [showCreator, setShowCreator] = useState(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showSummaryReport, setShowSummaryReport] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const inputConfig = useMemo(() => [
    {
      type: "range",
      name: "range",
      min: 1,
      max: 1000,
      value: range,
      onChange: (value) => {
        setRange(value);
        setResult(null);
      },
    },
    {
      type: "select",
      name: "creator",
      value: creator,
      onChange: (value) => {
        setCreator(parseInt(value));
        setShowCreator(false);
        setResult(null);
      },
    },
    {
      type: "select",
      name: "questionnaire",
      value: questionnaire,
      onChange: (value) => {
        setQuestionnaire(parseInt(value));
        setShowQuestionnaire(false);
        setResult(null);
      },
    },
    {
      type: "select",
      name: "feedback",
      value: feedback,
      onChange: (value) => {
        setFeedback(parseInt(value));
        setShowFeedback(false);
        setResult(null);
      },
    },
    {
      type: "select",
      name: "summaryReport",
      value: summaryReport,
      onChange: (value) => {
        setSummaryReport(parseInt(value));
        setShowSummaryReport(false);
        setResult(null);
      },
    }
  ], [range, creator, questionnaire, feedback, summaryReport]);
  const resetForm = () => {
    showRange && setShowRange(false);
    showCreator && setShowCreator(false);
    showQuestionnaire && setShowQuestionnaire(false);
    showFeedback && setShowFeedback(false);
    showSummaryReport && setShowSummaryReport(false);
  };
  const creatorOptions = useMemo(() => [
    {
      text: 'przeze mnie',
      value: 3250,
      addText: 'wraz z bezterminowym dostępem do platformy Ostendi'
    },
    {
      text: 'przez konsultanów Ostendi',
      value: 250*range,
      addText: 'bez bezterminowego dostępu do platformy Ostendi'
    }
  ], [range]);
  const questionnaireOptions = useMemo(() => [
    {
      text: 'Mam',
      value: 750,
      addText: 'własną ankietę'
    },
    {
      text: 'Nie mam',
      value: 1250,
      addText: 'własnej ankiety'
    }
  ]);
  const feedbackOptions = useMemo(() => [
    {
      text: 'osoby z mojej firmy',
      value: 0
    },
    {
      text: 'konsultantów Ostendi Global',
      value: 850*range
    }
  ], [range]);
  const summaryReportOptions = useMemo(() => [
    {
      text: 'Wymagany',
      value: 322
    },
    {
      text: 'Niewymagany',
      value: 0
    }
  ]);
  const priceSettings = useMemo(() => [
    {
      min: inputConfig[0].min,
      max: 9,
      price: 416
    },
    {
      min: 10,
      max: 29,
      price: 322
    },
    {
      min: 30,
      max: 44,
      price: 245
    },
    {
      min: 45,
      max: 59,
      price: 230
    },
    {
      min: 60,
      max: 89,
      price: 207,
    },
    {
      min: 90,
      max: 119,
      price: 180,
    },
    {
      min: 120,
      max: 199,
      price: 160,
    },
    {
      min: 200,
      max: 299,
      price: 140,
    },
    {
      min: 300,
      max: 499,
      price: 115,
    },
    {
      min: 500,
      max: inputConfig[0].max,
      price: 90
    }
  ]);
  const getPrice = () => {
    return priceSettings.map((interval, index) => {
      if (range >= interval.min && range <= interval.max) {
        setResult(range * interval.price + creatorOptions[creator].value + questionnaireOptions[questionnaire].value + feedbackOptions[feedback].value + summaryReportOptions[summaryReport].value);
        resetForm();
      }
    })
  };
  return (
    <>
      <form onClick={showRange ? () => setShowRange(false) : null}>
        <div className="calculator__number">1</div>
        <p>Chcę objąć oceną <span className="calculator__input-container" onClick={(e) => e.stopPropagation()}>
          <span className="link" onClick={() => {
            setShowRange(prevState => !prevState);
            resetForm();
          }}>{range} <ChevronDownIcon/></span>
          {showRange &&
          <Input className="calculator__slider"
                 name={inputConfig[0].name}
                 type={inputConfig[0].type}
                 min={inputConfig[0].min}
                 max={inputConfig[0].max}
                 value={inputConfig[0].value}
                 onChange={(e) => inputConfig[0].onChange(e.target.value)}/>
          }
        </span>
          osób. Badanie będzie realizowane <span className="calculator__input-container">
          <span className="link" onClick={() => {
            setShowCreator(prevState => !prevState);
            resetForm();
          }}>{creatorOptions[creator].text} <ChevronDownIcon/></span>
            {showCreator &&
            <Input className="calculator__select"
                   name={inputConfig[1].name}
                   type={inputConfig[1].type}
                   value={inputConfig[1].value}
                   options={creatorOptions}
                   onChange={(e) => inputConfig[1].onChange(e.target.getAttribute('value'))}/>
            }
        </span>
          {creatorOptions[creator].addText}. <span className="calculator__input-container">
          <span className="link" onClick={() => {
            setShowQuestionnaire(prevState => !prevState);
            resetForm();
          }}>{questionnaireOptions[questionnaire].text} <ChevronDownIcon/></span>
            {showQuestionnaire &&
            <Input className="calculator__select"
                   name={inputConfig[2].name}
                   type={inputConfig[2].type}
                   value={inputConfig[2].value}
                   options={questionnaireOptions}
                   onChange={(e) => inputConfig[2].onChange(e.target.getAttribute('value'))}/>
            }
        </span> {questionnaireOptions[questionnaire].addText}
          <span className="link-hover"> <InformationCircleOutlineIcon size={30}/> <span className="link-hover__tooltip">Ankieta do oceny 360 stopni to zestaw kompetencji wraz z definicjami oraz przypisanymi do poszczególnych kompetencji pytaniami / twierdzeniami.</span>
        </span> do przeprowadzenia badania kompetencji.</p>
        <div className="calculator__number">2</div>
        <p>
          Chcę, aby sesja informacji zwrotnej po zrealizowanym badaniu była przeprowadzona przez <span className="calculator__input-container">
            <span className="link" onClick={() => {
              setShowFeedback(prevState => !prevState);
              resetForm();
            }}>{feedbackOptions[feedback].text}&nbsp;<ChevronDownIcon/> </span>
          {showFeedback &&
          <Input className="calculator__select"
                 name={inputConfig[3].name}
                 type={inputConfig[3].type}
                 value={inputConfig[3].value}
                 options={feedbackOptions}
                 onChange={(e) => inputConfig[3].onChange(e.target.getAttribute('value'))}/>
          }
          </span>
          <span className="calculator__input-container">
            <span className="link" onClick={() => {
              setShowSummaryReport(prevState => !prevState);
              resetForm();
            }}>{summaryReportOptions[summaryReport].text} <ChevronDownIcon/> </span>
            {showSummaryReport &&
            <Input className="calculator__select"
                   name={inputConfig[4].name}
                   type={inputConfig[4].type}
                   value={inputConfig[4].value}
                   options={summaryReportOptions}
                   onChange={(e) => inputConfig[4].onChange(e.target.getAttribute('value'))}/>
            }
          </span> będzie raport podsumowujący (zbiorczy)
          <span className="link-hover"> <InformationCircleOutlineIcon size={30}/> <span className="link-hover__tooltip">Raport zbiorczy z oceny 360 stopni to zestawienie wyników wszystkich badanych osób. Dzięki raportowi zbiorczemu jesteś w stanie zobaczyć, jakie konkretnie mocne strony i obszary do rozwoju posiada cała oceniana grupa.</span>
          </span>
        </p>
        <div className="calculator__btns">
          <button type="button" className="calculator__btn" onClick={getPrice}>Oblicz teraz</button>
          <button type="button" className="calculator__btn calculator__btn--primary" onClick={() => setShowModal(true)}>Zobacz specjaną ofertę i oszczędź!</button>
        </div>
        {!!result &&
        <>
          <div className="calculator__result">
            <div className="calculator__result-all">Cena całościowa {result} zł</div>
            <div className="calculator__result-per-respondent">{Math.round(result/range * 100) / 100} zł za uczestnika</div>
          </div>
          <div className="calculator__notification">
            Przedstawiona oferta cenowa Oceny 360 stopni ma charakter informacyjnyi nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego. W celu zapoznania się ze szczegółową, dopasowaną do Twoich potrzeb ofertą, zapraszamy do kontaktu kontakt@ostendi.pl
          </div>
        </>
        }
      </form>
      {showModal && <div className="modal" id="modal">
        <div className="modal__form" data-aos="fade-up">
          <div className="modal__lead">
            <div>
              <h2>Zostaw nam swój kontakt i&nbsp;oszczędź.</h2>
              <p>Skontaktuj się z nami, a otrzymasz promocyjną ofertę przygotowaną specjalnie dla Ciebie.</p>
            </div>
            <div>
              <CloseIcon size={40} onClick={() => setShowModal(false)}/>
            </div>
          </div>
          <ContactForm calculatorResult={{
            result: result,
            range: range,
            creator: creatorOptions[creator].text + ' +' + creatorOptions[creator].value,
            questionnaire: questionnaireOptions[questionnaire].text + ' +' + questionnaireOptions[questionnaire].value,
            feedback: feedbackOptions[feedback].text + ' +' + feedbackOptions[feedback].value,
            summaryReport: summaryReportOptions[summaryReport].text + ' +' + summaryReportOptions[summaryReport].value,
          }} hideModal={() => setShowModal(false)}/>
        </div>
      </div>}
    </>
  )
}

export default IndexPage
