import React from "react";

const Input = ({type, name, text, value, onChange, min, max, className, options}) => {
  switch (type) {
  case 'checkbox':
    return <div className="form__group">
      <label className="form__checkbox">
        <input type={type} checked={value} onChange={onChange}
          placeholder={text} name={name}/>
        <small dangerouslySetInnerHTML={{__html: text}}/>
      </label>
    </div>
    break;
  case 'range':
    return <div className={className? "form__slider "+className : "form__slider"}>
      <button type="button" value={value <= min ? min : parseInt(value)-1} className="form__slider-btn" onClick={onChange}>-</button>
      <div className="form__slider-input">
        <input type={type} value={value} onChange={onChange} min={min} max={max}
          name={name}/>
        <div className="form__slider-scale form__slider-scale--division">
          <span>I</span>
          <span>I</span>
          <span>I</span>
        </div>
        <div className="form__slider-scale">
          <div>{min}</div>
          <div>{max}</div>
        </div>
      </div>
      <button type="button" value={value >= max ? max : parseInt(value)+1} className="form__slider-btn" onClick={onChange}>+</button>
    </div>
  case 'select':
    return <div className={className? "form__select "+className : "form__select"}>
      {options.map((option, index) => {
        return <div key={index} value={index} onClick={onChange}>
          {option.text}<br/>
          {option.addText && <small value={index}>{option.addText}</small>}
        </div>
      })}
    </div>
  default:
    return <div className="form__group">
      <label className="form__label" htmlFor={name}>{text}</label>
      <input type={type} value={value} onChange={onChange} placeholder={text}
        name={name} className="form__control"/>
    </div>
    break;
  }
}

export default Input;
